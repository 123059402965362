.product-Card-Container{
    height: fit-content;
    width: fit-content;
    color: white;
    justify-content: end;
    align-items: end;
}

.product-Card-Container:hover{
    cursor: pointer;
}

.product-Card-Container:hover .product-Card{
    margin-top: 0px;
    height: 380px;
    backdrop-filter: blur(5px);
    background:linear-gradient(to bottom, rgba(15, 15, 15, 0.267), rgb(41, 40, 40));
}

.product-Card-Container:hover .product-Card-Title{
  color: var(--eleColorLight);
}

.product-Card-Container:hover .product-Card-Body{
    color: rgb(255, 255, 255);
    margin-top: 10vh;
    backdrop-filter: blur(5px);
    visibility: visible;
}

.product-Card{
    position: absolute;
    z-index: 2;
    width: 576px;
    height: 100px;
    background:linear-gradient(to bottom, rgba(25, 25, 26, 0), rgb(61, 60, 60));
    display:  flex;
    margin-top: 280px;
    backdrop-filter: blur(1px);
    transition: .8s ease-out;
    overflow: hidden;
}

.product-Card-ThumbNail{
    z-index: -1;
    width: 576px;
    height: 380px;
}

.product-Card-Title{
    position: absolute;
    font-size: 4vh;
    font-weight: bold;
    width: fit-content;
    margin-top: 20px;
    margin-left: 10px;

    color: rgba(255, 255, 255, 0.856);
    transition: 1s;
}

.product-Card-Body{
    font-size: 1.1rem;
    height: fit-content;
    color: transparent;
    transition: 1.2s;
    width: 15vw;
    min-width: 250px;
    text-align: start;
    margin-left: 1vw;
    overflow: hidden;
    border-radius: 5px;
    backdrop-filter: blur(0.2px);
    padding: 2vh;
    margin-top: 100px;
    visibility: hidden;
}

@media (orientation:portrait) and (max-width: 650px){
    .product-Card{
        width: 84vw;
        margin-right: 3vw;
        margin-top: 150px;
    }
    .product-Card-ThumbNail{
        width: 84vw;
        margin-right: 3vw;
        max-height: 250px;
    }
    .product-Card-Body{
        font-size: 0.9rem;
    }

    .product-Card-Container:hover .product-Card{
        height: 250px;
    }
}