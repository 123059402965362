.bigBanner-Button{
    background-color: var(--eleColorLight);
    border: none;
    padding: 15px;
    margin-top: 2vh;
    color: var(--eleColorDark);
    font-size: 1.1rem;
    transition: 0.5s;
}

.bigBanner-Button:hover{
    cursor: pointer;
    background-color: white;
}

@media (orientation: portrait){

    .bigBanner-Button{
        margin: 10px;
    }

}