.sparesList-Container{
    width: 400px;
    display: none;
    z-index: 4;
}

.spareImg-box{
    width: fit-content;
    height: 100px;
    width: 100px;
    background-color: rgba(142, 120, 155, 0.308);
}

.sparesList{
    width: 400px;
    margin: 0;
    padding: 0;
}

.spareImg{
    width: 100px;
    margin: 5px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: rgba(179, 175, 187, 0.61);
    border-radius: 5px;
}