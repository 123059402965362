 :root {
    --eleColorDark: rgb(27,36,40);
    --eleColorLight: rgb(194,213,50);
    --flatColor:  rgb(62, 30, 114);
 }


.App {
  text-align: center;
  max-width: 100vw;
}

html{
  scroll-snap-type: y mandatory;
}
