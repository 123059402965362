.error-Msg-Container{
    display: flex;
    height: 72.3vh;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
}

.error-Msg-Header{
    font-size: 2rem;
    color: red;
    font-weight: bolder;
    margin-bottom: 2h;
}

.error-Msg-Body{
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0;
}

.HomeBtn{
    border: 0;
    background-color: red;
    color: white;
    font-size: 1.5vw;
    border-radius: 5px;
    padding: 10px;
    margin: 2vh;
    transition: 0.2s;
}

.HomeBtn:hover{
    background-color: rgb(151, 6, 1);
    cursor:pointer;
}