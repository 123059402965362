.imgView-Container{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.514);
    z-index: 10;
    top: 0
}

.expandedImg{
    max-width: 80%;
    min-width: 250px;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
}

@media (orientation: portrait){
    .expandedImg{
        max-width: 95%;
    }

    .imgView-Container{
        z-index: 10;
    }
}
