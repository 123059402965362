.bigBanner-Container{
    display: flex;
    flex-direction: column;
    color: white;
    width: 80vw;
    height: 400px;
    padding: 0;
    margin: 10vh;
    transition: 0.5s;
    box-shadow: 5px 10px 20px var(--eleColorDark) ;
}

#bBC-3{
    align-items: end;
}

.BigBanner-background{
    position: absolute;
    left: 0;
    top: 0;
    z-index: -10;
    width: 80vw;
    min-height: 400px;
}

.bigBanner-Body-Container{
    display: flex;
    flex-direction: column;
    height: 400px;
    width: 500px;
    background:var(--eleColorDark);
    align-items: center;
}


.bigBanner-Content{
    width: 85%;
}


.bigBanner-Header{
    font-size: 2rem;
    padding-top: 1vh;
}

.bigBanner-Body{
    font-size: 1.2rem;
    text-align: start;
    padding: 0vh 1vh;
}




@media (orientation:portrait) {

    .bigBanner-Container:nth-of-type(2){
        margin-top: 20vh;
    }

    .bigBanner-Container{
        height: fit-content;
        width: 90vw;
    }
    .bigBanner-Body-Container{
        width: 90vw;
        height: 500px;
        margin: 0;
        background: rgba(0, 0, 0, 0.70);
    }

    .BigBanner-background{
        width: 90vw;
        height: 500px;
        z-index: -10;
    }

    .bigBanner-Header{
        color:var(--eleColorLight);
    }
        
    .bigBanner-Background-Container{
        border-radius: 15px;
    }
}