html{
    overflow-y: scroll;
    overflow-x: hidden;
    width: fit-content;
  }

.aboutInfoContainer{
  display: flex;
  flex-direction: column;
}

.about-Info-Container  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
    margin-top: 15vh;
}

#info1{
  margin-top: 25vh;
}

#info3{
  margin-bottom: 15vh;
}

.about-Info-Header{
    font-size: 2.5rem;
    color: var(--eleColorLight);
}

.about-Info-Body{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 750px;
    width: 80vw;
    text-align: start;
    font-size: 1.5rem;
    color: var(--eleColorDark);
    padding: 25px;
    word-break: keep-all;
}

.about-Cards-Container{
    display: flex;
    justify-content: center;
    align-items: center;
}


::-webkit-scrollbar{
  background-color: rgba(149, 194, 194, 0);
}

::-webkit-scrollbar-track{
    background-color: rgb(228, 228, 228);
}
::-webkit-scrollbar-thumb{
    background-color: var(--eleColorLight);
  }

  @media (orientation: portrait){
    .about-Cards-Container{
        flex-direction: column;
    }
    .about-Info-Header{
      font-size: 2rem;
      margin: 0;
      padding: 0;
  }
  .about-Info-Body{
    font-size: 1.1rem;
    text-align: center;
  }
  }