.ContactPage-Container{
    display: flex;
    justify-content: start;
    align-items: start;
    min-height: 72.3vh;
    min-width: 98.8vw;
}

.contact-Info-Container{
    display: flex;
    justify-content: center;
    margin: 50px 50px;
    height: 100vh;
    width: 40vw;
}

.contact-Info-Box{
    color:white ;
    padding: 20px;
    background:var(--eleColorDark);
    border-radius: 15px;
    width: 20vw;
    min-width: 300px;
    height: fit-content;
    box-shadow: 10px 10px 20px var(--eleColorDark);
}

.contact-Header{
    color: var(--eleColorLight);
}

.contact-Body{
    font-size: 1.2rem;
    font-size: bolder;
    text-align: start;
}

@media (orientation: portrait){
    .contact-Info-Container{
        margin-top: 100px;
        width: fit-content;
        height: fit-content;
    }

    .ContactPage-Container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}