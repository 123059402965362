.infoCard-Box{
    display: flex;
    gap: 5vw;
    justify-content: center;
    align-items: center;
    width: 100vw;
    min-height: 650px;
    padding:2vh;
    background:var(--eleColorDark);
    box-shadow: 5px 5px 15px rgb(34, 32, 37);
}

.infoCard-Container{
    color: var(--eleColorDark);
    box-shadow: 5px 5px 15px rgb(30, 26, 37);
    border-radius: 15px;
}

.infoCard-Container .bigBanner-Button{
    background-color: var(--eleColorDark);
    color: var(--eleColorLight);
}

.infoCard-Container .bigBanner-Button:hover{
    background-color: white;
    color: var(--eleColorDark);
}

.infoCard-Header{
    color: var(--eleColorDark);
    font-size: 2rem;
    height: fit-content;
    text-align: start;
}
.infoCard-Info-Container{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.infoCard-Body-Container{
    display: flex;
    flex-direction: column;
    width: 25vw;
    height: 500px;
    min-width: 340px;
    min-height: 350px;
    background:var(--eleColorLight);
    backdrop-filter: blur(15px);
    padding: 2vh;
}

.infoCard-Body{
    font-size: 1.2rem;
    text-align: start;
}

.infoCard-Background-Container{
    padding: 0;
    margin: 0;
    width: 20vw;
}
.infoCard-Background{
    top:8.5vh;
    padding: 0;
    margin: 0;
    width: 20vw;
}

@media (orientation:portrait){
    .infoCard-Box{
        flex-direction: column;
        width: 92vw;
    }
    
    .infoCard-Body-Container{
        width: 80vw;
    }
    .infoCard-Container{
        margin-top: 2vh;
        margin-bottom: 2vh;
    }
    .infoCard-Background{border-radius: 15px;}
}