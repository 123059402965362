.product-Info-Container{
    display: flex;
    flex-direction: column;
    width: 100vw;
    margin-top: 10vh;
    justify-content: center;
    align-items: center;
    background-color: rgb(245, 245, 245);
}

.product-Info-Row{
    display: flex;
    justify-content: center;
    gap: 2vw;
    width: 77vw;
    margin-bottom:10vh;
}
.product-Row-Box{
    width: 23vw;
    max-width: 350px;
    min-width: 250px;
    background:var(--eleColorDark);
    padding: 2vw;
    border-radius: 5px;
    box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.322);
}

.product-Bbox-List{
    display: flex;
    flex-direction: column;
    text-align: start;
    width: 450px;
}

.product-Bbox-Item{
    font-size: 1.2rem;
    font-weight: 500;
    margin: 1vh;
    color: white;
}

.product-Rbox-Titel{
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--eleColorLight);
}

.product-Rbox-Body{
    font-size: 1.2rem;
    color: rgb(255, 255, 255);
    font-weight: 500;
}

.product-Body-Box{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: fit-content;
    margin-bottom: 10vh;
    background:var(--eleColorDark);
    padding: 5vh 0 5vh 0;
    box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.322);
}

.product-Bbox-Info{
    display: flex;
    margin-bottom: 2vh;
}

.product-Bbox-Info:nth-child(2){
    display: flex;
    margin-bottom: 2vh;
}


.product-Bbox-Conainer{
    display: flex;
    max-width: 500px;
    flex-direction: column;
    justify-content: center;
    margin: 2.5vh;
}

.product-Bbox-Title{
    font-size: 2rem;
    font-weight: bold;
    width: fit-content;
    margin-top: 2vh;
    color: var(--eleColorLight);
}

.product-Bbox-Card{
    margin: 4vw;
    margin-bottom: 10vh;
    background:var(--eleColorDark);
    padding: 2vw;
    backdrop-filter: blur(5px);
    box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.322);
    max-width: 500px;
}

.product-Bbox-Body{
    width: 40vw;
    max-width: 500px;
    text-align: start;
    text-justify: center;
    font-size: 1.2rem;
    font-weight: 500;
    color: white;
}
.product-Bbox-Img{
    max-width: 500px;
    height: 35vh;
    max-height: 550px;
    align-self: center;
    margin: 1.25vw;
    min-width: 300px;
}

.product-Card-Img{
    max-width: 500px;
    width: 40vw;
    max-height: 320px;
    height: 30vh;
    min-width: 300px;
    
}


@media (orientation:portrait){
    .product-Info-Row{
        flex-direction: column;
        align-items: center;
        width: 100vw;
    }

    .product-Row-Box{
        padding: 15px;
    }

    .product-Info-Container{
        width: 100vw;
        justify-content: center;
        align-items: center;
    }

    .product-Bbox-Conainer{
        width: 80vw;
        justify-content: center;
        align-items: center;
    }

    .product-Bbox-Info{
        display: flex;
        width: 100vw;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .product-Body-Box{
        width: fit-content;
    }

    .product-Bbox-Img{
        max-width: 350px;
        max-height: 350px;
        border: none;
    }

    .product-Bbox-Title{
        margin-top: 20px;
        text-align: center;
    }

    .product-Card-Img{
        width: 350px;
        height: 320px;
        border-radius: 5px;
        margin-left:0;
    }

    .product-Bbox-Card{
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
        margin-top:2vh;
    }

    .product-Bbox-Body{
        display: flex;
        flex-direction: column;
        width: 80vw;
        padding-top: 10px;
        padding-bottom: 10px;
        text-align: center;
        justify-content: start;
        align-items: start;
    }

    .product-Bbox-List{
        width: 80vw;
        text-align: center;
        align-items: center;
    }

    .product-Bbox-Item{
        width: 80vw;
    }

}