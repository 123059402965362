body{
  overflow-x: clip;
  background-color: rgb(255, 255, 255);
}

.navBar-Container {
  top: 0;
  display: flex;
  position: sticky;
  flex-direction: row;
  max-width: 100vw;
  background-color: rgba(255, 255, 255, 0);
  z-index: 10;
}

.navBar-Logo{
  padding: 7px;
  margin-left: 5vw;
  width: 200px;
  height: 62px;
}

.button-List-Container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

.button-List{
  display: flex;
  width: 36vw;
  margin: 0;
  justify-content: space-between;
}

.nav-A-Button{
  border: none;
  background-color: rgba(255, 255, 255, 0);
  font-size: 1.15rem;
  font-weight: 300;
  padding: 0.5vw;
  margin: 0 0.5vw;
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  border-top: 5px solid rgba(255, 255, 255, 0);
  color: rgb(255, 255, 255);
  transition: 0.4s;
}

#KONTAKT{
  background:var(--eleColorLight);
  transition:0.5s;
  color: var(--eleColorDark);
}
.nav-A-Button:hover{
  color: rgb(255, 255, 255);
  border-bottom: 5px solid var(--eleColorLight);
  cursor: pointer;
}

.nav-A-Button#KONTAKT:hover{
  background: var(--eleColorDark);
  color: var(--eleColorLight);
  border-bottom: 5px solid transparent;
}

.nav-W-Button#KONTAKT:hover{
  background: white;
  color: var(--eleColorDark);
  border-bottom: 5px solid transparent;
}

@media (orientation: portrait){
  .navBar-Container{
    position: fixed;
  }
}