.documentList-Container{
    display: none;
}

.documentList{
    display: flex;
    flex-direction: column;
    list-style: none;
    text-decoration: none;
    width: 370px;
    margin: 0;
    padding: 0;
}

.pdfDocument{
    display: flex;
    text-align: center;
    align-items: center;
    color: rgb(245, 245, 245);
    font-size: 1.2rem;
    font-weight: 500;
    height: 3rem;
    max-width: 370px;
    text-align: start;
    padding-top:10px;
    padding-bottom: 10px;
    padding-left: 10px;
    list-style: none;
    text-decoration: none;
    border-bottom: 1px solid black;
    transition: 0.5s;
}

.pdfDocument:first-of-type{
    border-top: 1px solid black;
}

.pdfDocument:hover{
    padding-left: 20px;
    color: var(--eleColorLight);
}