.product-Gallery-Container{
    display: flex;
    justify-content: center;
    margin-top: 100vh;
    margin-bottom: 5vh;
}
.product-Gallery-Row{
    display: flex;
    gap:2vh;
    padding: 2vh;
    max-width: 75vw;
    overflow-x: scroll;
    background-color: var(--eleColorDark);
    
}
.product-Gallery-Img{
    height: 225px;
    border: 4px solid transparent;
    border-radius: 5px;
    transition: 0.5s;
}

.product-Gallery-Img:hover{
    border: 4px solid var(--eleColorLight);
}

@media (orientation: portrait){
.product-Gallery-Img{
    height: 180px;
}
}


