.about-Card{
    display: flex;
    flex-direction: column;
    margin: 2.5vh;
    padding: 2.5vh;
    background: var(--eleColorDark);
    backdrop-filter: blur(5px);
    color: white;
    border-radius: 15px;
    box-shadow: 10px 10px 20px var(--eleColorDark);
    width: 250px;
    max-width: 300px;
    height: 100px;
}

.about-Card-Header{
    font-size: 1.5rem;
    font-weight: bold;
}

.about-Card-Body{
    font-size: 1.2rem;
}

@media (orientation: portrait){
.about-Card{
    padding: 5px;
}

.about-Card-Header{
    font-size: 1.2rem;
}

.about-Card-Body{
    font-size: 1rem;
}

}