.ContactMailForm{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    margin:5vh 0 2vh 0;
    background-color: rgb(236, 236, 236);
    padding: 2vw 5vw;
    width: 20vw;
    min-width: 300px;
}

.portSpecForm{
    display: flex;
    flex-direction: column;
}

.ContactMailForm-PortNr{
    color: rgb(206, 74, 74);
    margin: 2vh 0 0 0;
    text-align: start;
    border-top: 4px solid var(--eleColorLight);
}

.ContactMailFormCredentialsForm{
    display: flex;
    flex-direction: column;
    text-align: start;
    width: fit-content;
    min-width: 300px;
    border-top: 2px solid gray;
    margin: 10px 0;
}
.ContactMailForm-Header{
    font-size: 3rem;
    text-align: center;
    margin: 0;
    color: var(--eleColorDark);
}
.ContactMailFormInput-Number{
    width: 6rem;
    height: 2rem;
    font-size: 1.2rem;
    border: 1px solid gray;
    padding: 5px 10px;
}

.ContactMailFormInput-Number:focus{
    outline-color: var(--eleColorLight);
}

.ContactMailFormLabel{
    text-align: start;
    color: rgb(71, 71, 71);
    font-weight: bold;
    font-size: 1.2rem;
    margin: 2vh 0 0.5vh 0;
}

.ContactMailFormInput-Text{
    height: 2rem;
    width: 20vw;
    min-width: 280px;
    font-size: 1.2em;
    border: 1px solid gray;
    padding: 5px 10px;
    margin: 5px;
}

.ContactMailFormInput-Text{
    outline-color: var(--eleColorLight);
}

.ContactMialFormButton{
    margin-top: 5vh;
    width: 150px;
    height: 50px;
    background-color: var(--eleColorLight);
    border: none;
    font-size: 1.2rem;
    transition: .5s;
}

.ContactMialFormButton:hover{
    background-color: var(--eleColorDark);
    color: var(--eleColorLight);
}

.ContactMailFormSelect{
    height: 2.1rem;
    width: 20vw;
    min-width: 200px;
    font-size: 1.1rem;
    border: 1px solid gray;
    padding: 5px 5px;
}

.ContactMailFormSelect:focus{
    outline-color: var(--eleColorLight);
}

.radioInputList{
    width: fit-content;
    text-align: start;
    margin: 5px;
    font-weight: bold;
    color: rgb(70, 69, 69);
}

.ContactMailFormSpec{
    display: flex;
    flex-direction: column;
    text-align: start;
    width: fit-content;
    min-width: 300px;
    border-top: 2px solid gray;
    margin: 10px 0;
}

.isRequired{
    color: rgb(206, 74, 74);
    margin-left:2px;
    font-size: 1.2rem;
}