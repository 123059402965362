.body{width: 100vw;
    height: fit-content;}

.landingBox-Container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height:90vh;
    z-index: 2;
  
}
.landingBox{
    display: flex;
    flex-direction: column;
    position: absolute;
    overflow: hidden;
    bottom: 9vh;
    z-index: -1;
    
}


.landingBox-Body-Container{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height:100vh;
    width: 100vw;
    backdrop-filter: blur(5px);
}

.landingBox-Header{
    color: var(--eleColorLight);
    font-size: 2em;
    font-weight: bolder;
    background: var(--eleColorDark);
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 0.25vh;
}

.landingBox-Body{
    color: var(--eleColorLight);
    font-size: 1.2rem;
    font-weight: bold;
    background: var(--eleColorDark);
    padding: 10px;
    border-radius: 5px;
    margin-top: 0.25vh;
}

.partner-Container{
    position: absolute;
    display: flex;
    padding: 2.5vh 0;
    background:var(--eleColorDark);
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 5vh;
    min-height: 50px;
    box-shadow: 5px 5px 15px var(--eleColorDark);
    bottom: 0;
}

.partner-Logo{
    position: relative;
    max-height: 45px;
    max-width: 125px;
    width: 17vw;
    margin-left: 5vw;
    opacity: 0.8;
}
.landingBox-Img{
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    background-color: gray;
}

@media (orientation: portrait) {
    .landingBox{
        bottom: 0;
    }
    .partner-Logo{
        width: 17vw;
    }
    .landingBox-Header{
        font-size: 1.4rem;
    }
    .landingBox-Body{
        font-size: 1.2rem;
        width: 100vw;
        max-width: 550px;
    }
    .landingBox-Img{
        right: -200px;
        width: 1200px;
        height: 100vh;

    }

}
