.sideBar-Container{
    display: flex;
    justify-content: end;
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0);
    visibility: hidden;
    z-index: 1;
}
.sideBar{
    width: 100vw;
    max-width: 500px;
    background-color: var(--eleColorDark);
    overflow-y: scroll;
    overscroll-behavior: contain;
}

.exitBtn-Container{
    display: flex;
    justify-content: start;
    align-items: center;
    height: 5vh;
}

.exitBtn{
    height: 4rem;
    width: 4rem;
    font-size: 2rem;
    border-radius: 5px;
    margin-top: 4vh;
    color: white;
    background-color: transparent;
    border: none;
    text-align: center;
}

.exitBtn:hover{
    cursor: pointer;
    color: red;
}

.sideBar-button-List{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding: 2vh;
}

.sideBar-Button{
    display: flex;
    align-items: center;
    justify-content: start;
    border-top:1px solid black;
    height: 3rem;
    width: 80vw;
    max-width: 420px;
    padding: 1vh;
}

.sideBar-Button:last-of-type{
    border-bottom: 1px solid black;
}

.siderBar-Collection{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    height: fit-content;
    margin-top: 2vh;
    margin-left: 30px;
}

.collection-Title-Container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.collection-Title{
    font-weight: bold;
    font-size: 1.5rem;
    width: fit-content;
    color: var(--eleColorLight);
}

.dd-Icon{
    color:var(--eleColorLight);
    font-size: 1.5rem;
    padding-left: 10px;
    transition: 0.5s;
}

.collection-Title-Container:hover .dd-Icon{
    color: white;
}

.collection-Title-Container:hover .collection-Title{
    color: var(--eleColorLight);
}

.collection-Title:hover{
    cursor: pointer;
}

@media (orientation:portrait){
   
}